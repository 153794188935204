<template>
  <client-only>
    <div class="error-page">
      <div class="error-page__content">
        <Icon404 />
        <span>Không tìm thấy trang, vui lòng quay lại sau!</span>
        <BaseButton class="base-button--btn-primary" @click="goHome">trang chủ</BaseButton>
      </div>
      <div class="error-page__bg" />
    </div>
  </client-only>
</template>

<script setup lang="ts">
import Icon404 from './Icon404.vue'
import BaseButton from '~/components/common/base-button.vue'

const router = useRouter()

const goHome = () => {
  router.push('/')
}
</script>

<style scoped lang="scss" src="~/assets/scss/page/error-pc.scss"></style>
