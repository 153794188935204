<template>
  <component :is="component" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useBase from '~/composables/useBase'
import Desktop from '~/components/desktop/error/index.vue'
import Mobile from '~/components/mobile/error/index.vue'
import { useAppStore } from '~/store/app'

useBase()

const { $device } = useNuxtApp()
const store = useAppStore()
const { isLogged } = storeToRefs(store)
const component = $device.isMobileOrTablet ? Mobile : Desktop
</script>
